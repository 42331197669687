
import { Box, Container, Flex, Heading, Image, Text, Link, Em, Button } from "@chakra-ui/react";

export const Helpbar: React.FC = () => {
  

  return (
<Box  py={10} textAlign="center">
  <Container maxW="container.sm">
    
    {/* Titolo */}
    <Heading fontSize="2xl" fontWeight="bold" color="black" mb={1}>
      Hai bisogno di aiuto?
    </Heading>

    {/* Sottotitolo */}
    <Text fontSize="lg" color="gray.600" mb={6}>
      Contattaci
    </Text>

    {/* Icone con link */}
    <Flex justify="center" gap={6}>
      
      {/* Email */}
      <Link href="mailto:info@kiddocare.it">
        <Box 
          bg="yellow.400" 
          w="80px" 
          h="80px" 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          borderRadius="full"
          boxShadow="md"
          _hover={{ bg: "yellow.500" }}
        >
          <Image src="/images/email.png" alt="Email" boxSize="30px" />
        </Box>
      </Link>

      {/* WhatsApp */}
      <Link href="https://wa.me/393762385724">
        <Box 
          bg="teal.500" 
          w="80px" 
          h="80px" 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          borderRadius="full"
          boxShadow="md"
          _hover={{ bg: "teal.600" }}
        >
          <Image src="/images/whatsapp.png" alt="WhatsApp" boxSize="30px" />
        </Box>
      </Link>

    </Flex>

  </Container>
</Box>
);
}
