import React from 'react';
import { Box, Container, Flex, Heading, HStack, Link, Text, VStack, Image } from '@chakra-ui/react';
import { Helpbar } from "../../layouts/helpbar";

export const SuccessPage: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={6}>
      <Container maxW="container.md" textAlign="center">
        
        {/* Immagine centrale (più piccola su schermi piccoli) */}
        <Flex justifyContent="center" mb={5}>
          <Image 
            src="/images/transazione.png" 
            alt="Success" 
            height={{ base: "35px", md: "50px" }} 
          />
        </Flex>

        {/* Titolo principale */}
        <Heading as="h1" size="lg" color="gray.800" fontWeight="bold" mb={6}>
          Ti diamo il benvenuto su Kiddocare.
        </Heading>

        {/* Sezione contenuti - Perfettamente allineata */}
        <Flex 
          direction={{ base: "column", md: "row" }} 
          justify="center" 
          align="center"
          width="100%"
        >
          
          {/* Sezione Credenziali */}
          <VStack textAlign="center" maxW="320px" flex="1" align="center" mb={{ base: 10, md: 0 }}>
            <Image src="/images/mail.png" alt="Email Icon" pt ="4" height="90px" mb={4} />
            <Text fontWeight="bold">Cerchi le credenziali?</Text>
            <Text fontSize="sm">
              Le troverai nell'email che ci hai fornito durante l'acquisto!
            </Text>
            <Text fontSize="xs" color="gray.500" mt={2}>
              Se non trovi la mail, controlla nella casella di posta indesiderata.
            </Text>
          </VStack>

          {/* Spazio tra le colonne su desktop */}
          <Box display={{ base: "none", md: "block" }} width="90px" />

          {/* Sezione Download App */}
          <VStack textAlign="center" maxW="320px" flex="1" align="center">
            <Image src="/images/scarica.png" alt="Download Icon" height="80px" mb={3} />
            <Text fontWeight="bold">Non dimenticare di scaricare l'APP</Text>
            <Text fontSize="sm">per usare le tue credenziali!</Text>
            <HStack mt={2}>
              <Link href="https://apps.apple.com/it/app/kiddocare/id6739247816">
                <Image 
                  src="/images/appstore.png" 
                  alt="Download on the App Store" 
                  height="40px"
                />
              </Link>
              <Link href="https://play.google.com/store">
                <Image 
                  src="/images/googleplay.png" 
                  alt="Get it on Google Play" 
                  height="40px"
                />
              </Link>
            </HStack>
          </VStack>

        </Flex>

        {/* Helpbar */}
        <Box mt={6}>
          <Helpbar />
        </Box>
      </Container>
    </Box>
  );
};
