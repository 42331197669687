import { Box, Container, Image, Link, SimpleGrid, Stack, Text, Flex } from '@chakra-ui/react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';

export const Footer: React.FC = () => {
  return (
    <Box bg="gray.700" color="whiteAlpha.900" py={10}>
      <Container maxW="container.xl">
        <SimpleGrid columns={{ base: 1, md: 3 }} justifyContent="space-between">
          
          {/* Colonna Sinistra: Logo & Descrizione */}
          <Stack align="flex-start" maxW="300px">
            <Image src="/images/Logo_Kiddocare.png" alt="Kiddocare" height="40px" mb={3} />
            <Text fontSize="sm" mb={3}>
              Prenota visite pediatriche online e consulta il tuo pediatra direttamente dal tuo smartphone.
            </Text>
            <Text fontSize="sm" mb={3}>
              <strong>AIHEALTH S.r.l.</strong><br />
              Via Santa Sofia 24, 20122 Milano (MI)<br />
              P.IVA 13640270966<br />
              <Link href="mailto:aihealth@pec.it" color="whiteAlpha.900" _hover={{ textDecoration: 'underline' }}>
                aihealth@pec.it
              </Link>
            </Text>
          </Stack>

          {/* Colonna Centrale: Link di navigazione */}
          <Stack align="flex-start" pt={10}>
            <Link href="https://ai-health.it/" color="whiteAlpha.900" _hover={{ textDecoration: 'underline' }} mb={3}>
              Cosa facciamo
            </Link>
            <Link href="https://ai-health.it/#chi-siamo" color="whiteAlpha.900" _hover={{ textDecoration: 'underline' }} mb={3}>
              Chi siamo
            </Link>
            <Link href="../../policies/parents/policy-privacy" color="whiteAlpha.900" _hover={{ textDecoration: 'underline' }} mb={3}>
              Termini e Condizioni & Privacy e Policy
            </Link>
            
          </Stack>

          {/* Colonna Destra: Social & Contatti */}
          <Stack align="flex-start" pt={10} >
            {/* Sezione Social */}
            <Text fontSize="md" fontWeight="bold" mb={0}>Seguici su</Text>
            <Flex gap={4} mb={4}>
              <Link href="https://www.linkedin.com/company/aihealth-s-r-l" >
                <FaLinkedin size={22} color="white" />
              </Link>
              <Link href="https://www.instagram.com/kiddocare.it/" >
                <FaInstagram size={22} color="white" />
              </Link>
            </Flex>

            {/* Contatti */}
            <Text fontSize="md" fontWeight="bold" mb={0}>Contattaci</Text>
            <Link href="mailto:info@kiddocare.it" color="whiteAlpha.900" _hover={{ textDecoration: 'underline' }} mb={4}>
              info@kiddocare.it
            </Link>

            <Text fontSize="md" fontWeight="bold" mb={0}>Supporto clienti</Text>
            <Link href="mailto:care@kiddocare.it" color="whiteAlpha.900" _hover={{ textDecoration: 'underline' }} mb={4}>
              care@kiddocare.it
            </Link>

            <Text fontSize="md" fontWeight="bold" mb={0}>Supporto pediatri</Text>
            <Link href="mailto:support@kiddocare.it" color="whiteAlpha.900" _hover={{ textDecoration: 'underline' }}>
              support@kiddocare.it
            </Link>
          </Stack>

        </SimpleGrid>

        {/* Copyright */}
        <Text textAlign="center" fontSize="sm" pt={8}>
          © {new Date().getFullYear()} Kiddocare. Tutti i diritti riservati
        </Text>
      </Container>
    </Box>
  );
};
