import { 
  Box, 
  Container, 
  Flex, 
  Image, 
  Link, 
  Button, 
  Stack 
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false); // Stato per il menu mobile

  return (
    <Box as="nav" bg="white" px={4} py={2} boxShadow="sm" borderBottom="1px solid" borderColor="gray.200">
      <Container maxW="container.lg">
        <Flex align="center" justify="space-between">
          
          {/* Logo */}
          <Image src="/logos/kiddocare-full.png" height="40px" alt="Kiddocare Logo" />

          {/* Link di navigazione - Solo su desktop */}
          <Stack direction="row" gap={10} display={{ base: 'none', md: 'flex' }}>
            <Link href={'https://ai-health.it/'} fontSize="md" fontWeight="bold" color="black" zIndex="1000"
              >
              Cosa Facciamo
            </Link>
            <Link href={'https://ai-health.it/#chi-siamo'} fontSize="md" fontWeight="bold" color="black" zIndex="1000">
              Chi Siamo
            </Link>
            <Link href={'mailto:info@kiddocare.it'} fontSize="md" fontWeight="bold" color="black" zIndex="1000">
              Contatti
            </Link>
            
          </Stack>

          {/* Pulsanti - Solo su desktop */}
          <Stack direction="row" display={{ base: 'none', md: 'flex' }}>
            <Button 
              variant="outline" 
              borderColor="primaryPediatrician.600" 
              color="primaryPediatrician.600" 
              borderRadius="full"
              _hover={{ bg: "blue.100" }}
            >
              Sei un pediatra?
            </Button>
            <Button bg="primaryPatient.600" color="white" borderRadius="full" zIndex="1000" _hover={{ bg: "primaryPatient.400" }}>
              Abbonati
            </Button>
          </Stack>

          {/* Bottone per aprire il menu su mobile (Hamburger) */}
          <Box 
            display={{ base: 'flex', md: 'none' }} 
            flexDirection="column" 
            justifyContent="center" 
            gap="4px"
            onClick={() => setMenuOpen(!menuOpen)} // Toggle menu
            cursor="pointer"
            zIndex="1000"
          >
            <Box w="25px" h="3px" bg="primaryPatient.600" borderRadius="md"></Box>
            <Box w="25px" h="3px" bg="primaryPatient.600" borderRadius="md"></Box>
            <Box w="25px" h="3px" bg="primaryPatient.600" borderRadius="md"></Box>
          </Box>

        </Flex>
      </Container>
    
      {/* Mobile Navigation Menu */}
      <Box 
        pb={4} 
        pt={4}
        px={4} 
        bg="white" 
        display={menuOpen ? "block" : "none"} 
      >
        <Stack as="nav" gap={4}>
          <Link href={'https://ai-health.it/'} fontSize="md" fontWeight="bold" color="black" zIndex="1000"
           >
            Cosa Facciamo
          </Link>
          <Link href={'https://ai-health.it/#chi-siamo'} fontSize="md" fontWeight="bold" color="black" zIndex="1000"
           >
            Chi Siamo
          </Link>
          <Link href={'mailto:info@kiddocare.it'} fontSize="md" fontWeight="bold" color="black" zIndex="1000"
           >
            Contatti
          </Link>


          
          <Button 
            variant="outline" 
            borderColor="primaryPediatrician.600" 
            color="primaryPediatrician.600" 
            borderRadius="full"
            
          >
            Sei un pediatra?
          </Button>
          <Button bg="primaryPatient.600" color="white" borderRadius="full" zIndex="1000"_hover={{ bg: "primaryPatient.400" }}>
            Abbonati
          </Button>
        </Stack>
      </Box>

    </Box>
  );
};
