import { Box, Text, VStack, Em } from '@chakra-ui/react';
import React, { useEffect } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const STRIPE_CLIENT_REFERENCE_ID = process.env.REACT_APP_STRIPE_CLIENT_REFERENCE_ID;

console.log('VARIABILI', STRIPE_CLIENT_REFERENCE_ID, STRIPE_PRICING_TABLE_ID, STRIPE_PUBLISHABLE_KEY);

export const PricingTableStripe: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  return (
    <Box py={12} bg={"secondaryPatient.500"}>

      <VStack align="center">
        {/* Elegant text section */}
        <Box
          textAlign="center"
          mb={8}
        >
          <Text
            fontSize={{ base: "xl", md: "2xl" }}
            fontWeight="medium"
            letterSpacing="tight"
            _hover={{
              transform: "translateY(-3px)",
              transition: "all 0.2s ease"
            }}
          >
            Scegli il tuo piano, ricevi le credenziali e scarica l'app</Text>
            <Text fontSize={{ base: "sm", md: "sm" }}
            > <Em>Ricorda di utilizzare il codice di invito fornito dal tuo pediatra</Em></Text>
          
        </Box>
      </VStack>

      <stripe-pricing-table
        pricing-table-id={STRIPE_PRICING_TABLE_ID}
        publishable-key={STRIPE_PUBLISHABLE_KEY}
        client-reference-id={STRIPE_CLIENT_REFERENCE_ID}
      />
    </Box>
  );
};